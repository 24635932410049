.chips-container {
  display: flex;
  flex-wrap: wrap;
  column-gap: 0.75rem;
  row-gap: 0.7rem;
}
.chip-item {
  border-radius: 0.6rem !important;
  & > span {
    font-size: 1.1rem !important;
  }

  & > svg {
    font-size: 20px !important;
  }
}
