.header-logo-container{
    width: 80px;
    height: 35px;

    & > img{
        object-fit: contain;
        width: 100%;
        height: 100%;
    }

    
}

.header-brand{
    padding: .5rem;
    font-size: 2.2rem;
    font-family: $main-font-family;
    color: #000000;
    text-decoration: none;
    display: flex;
    align-items: center;

    & p {
        margin: 0 !important;
    }
    
    &__bim {
        font-weight: 700;
    }
}