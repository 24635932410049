.MuiStepLabel-alternativeLabel{
    font-size: 1.2rem !important;

    & > svg {
        font-size: 2rem;

        & > text {
            font-size: 1.2rem;
        }
    }
}