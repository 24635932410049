.copy-icon{
    position: absolute !important;
    right: 1rem;
    top: 50%;
    transform: translate(0, -50%);

    &:hover {
        background-color: unset !important;
    }
}

.authorize-users-input-container{
    margin-bottom: 1rem;
}