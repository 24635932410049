/* -------------------------------------------------------------------------- */
/*                            RESET REACT-BOOSTRAP                            */
/* -------------------------------------------------------------------------- */
.btn.btn-primary {
    background-color: $c-blue;
    border: $c-darkBlue;

}

.btn.btn-secondary:hover {
    color: white;
}

