.settings-layout-content {
  flex: 1;
  padding-left: 8rem;
  padding-right: 8rem;
}

.settings-content-container {
  margin-top: 3rem;
  display: grid;
  grid-template-columns: 20% 80%;
}

.settings-section-container {
  // background: rgb(254 254 254);
  padding: 3rem;
  border-radius: 1rem;
  // box-shadow: 0 0 1rem rgba(0, 0, 0, 0.1);

  & h3 {
    margin-top: 0;
  }
}

.sidebar-settings {
  display: flex;
  flex-direction: column;
  border-right: 1px solid #bbbbbb;
  height: 100%;
}

.navlink-aside-item {
  &:hover {
    background-color: #fafafa;
  }

  &.active {
    background-color: #ffffff25 !important;
  }
}

.sidebar-item-button {
  display: flex;
  gap: 1rem;
  color: black;
  & * {
    color: black;
  }

  &:hover {
    background-color: unset !important;
  }
}

.card-product-setting-container {
  margin-top: 2.5rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 400px));
  grid-gap: 2.5rem;
  // justify-content: center;
  grid-auto-rows: 1fr;
}

.card-product-setting {
  // border: 1px solid black;
  position: relative;
  border-radius: 0.5rem;
  padding: 1rem;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.1);
  background-color: white;
  border: 2px solid transparent;
  display: flex;
  flex-direction: column;

  &--selected {
    border-color: $c-blue;
  }
}

.custom-check-icon-absolute {
  position: absolute;
  background: white;
  top: -12px;
  right: -8px;
  transform: scale(1.3);
}
