#dropdown-views-viewer {
  display: flex !important;
  align-items: center;
  gap: 0.85rem;
}

.dropdown-views-viewer {
  & .dropdown-menu {
    max-height: 500px;
    overflow-y: auto;
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 1rem;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    &:hover {
      background-color: rgb(238, 238, 238);
    }

    &--selected {
      background-color: rgb(218, 218, 218);
    }
  }
}
