.offcanvas-properties-container {
  display: flex;
  flex-direction: column;
  height: 100vh; 
}

.offcanvas-properties-footer {
  padding: 1rem;
}

.properties-category-name {
  & > button {
    font-size: 12px;
  }
}

.offcanvas-properties-body{
  flex: 1;
  display: flex;
  flex-direction: column;
}

.properties-container {
  overflow-y: auto;
}

.search-properties-container {
  width: 100%;
  padding: 0 1rem;
  display: flex;
  align-items: center;
  border: 1px solid #ced4da;
  border-radius: .875rem;
  margin-bottom: 1rem;
}

.search-properties-item {
  width: 100%;
  padding: 0.3rem 1rem;
  border: none !important;
}

.search-properties-item:focus {
  outline: none;
}

/* SCROLL BAR */
.properties-container {
  margin-top: 1rem;
  /* activar el overflow con scroll */
  overflow: auto;

  /* personalizar el color de fondo del scrollbar */
  scrollbar-color: #ccc #f4f4f4;

  /* personalizar el ancho del scrollbar */
  scrollbar-width: thin;
}

/* estilo del scrollbar en Chrome y Safari */
.properties-container::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.properties-container::-webkit-scrollbar-track {
  background: #f4f4f4;
}

.properties-container::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 6px;
}

/* estilo del scrollbar en Firefox */
.properties-container {
  scrollbar-color: #ccc #f4f4f4;
}

.properties-container scrollbar-thumb {
  background-color: #ccc;
  border-radius: 6px;
}