.dropdown-menu-login {
  &:hover {
    background: unset !important;
  }
}

.dropdown-menu-item {
  & span {
    font-size: 1.2rem !important;
  }
}
