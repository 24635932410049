.forge-viewer {
  position: relative;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.loader {
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  visibility: visible;
  text-align: center;
  /* modified */
  display: inline-block;
  width: 233px;
  height: 200px;
  transform: rotate(45deg);
  transform-origin: 355px 260px;
}
