.table-pagination-container {
    font-size: 1rem !important;
    padding: 0rem 0 0 0;
    padding-left: 0;
    border-top: solid .05rem rgba(128, 128, 128, 0.137);

    & .MuiTablePagination-spacer{
        flex: 0 !important;
        -webkit-flex: 0 !important;
    }
    & > div {
        display: flex !important;
        padding: 0;
        padding-left: .5rem;
    }
    & p{
        font-size: 1.1rem !important;
        margin-bottom: 0 !important;
    }

    & .MuiTablePagination-displayedRows{
        flex: 1;
        text-align: right;
    }

    & .MuiTablePagination-actions{
        margin-left: .875rem !important;
    }
}