.recursos-image-container {
  width: 100%;
  height: 40vh;
  // filter: saturate(0.5);
  background-position: center center !important;
  background-blend-mode: darken;
  place-items: center;
  background-size: cover;
  position: relative;
}
.recursos-image {
  position: absolute;
  background: linear-gradient(
    195deg,
    rgba(66, 66, 74, 0.5),
    rgba(25, 25, 25, 0.5)
  );
  background-position: center center !important;
  background-blend-mode: darken;
  place-items: center;
  background-size: cover;
}

.recursos-container {
  background-clip: border-box;
  backdrop-filter: saturate(200%) blur(30px);
  background-color: rgba(249, 249, 249, 0.8);
  margin-left: 8rem;
  margin-right: 8rem;
  margin-top: -7rem;
  height: 400px;
  border-radius: 10px;
  margin-bottom: 3rem;
  box-shadow: rgba(0, 0, 0, 0.05) 0rem 1.25rem 1.6875rem 0rem;
  height: auto;
}

.cards-recursos-container {
  padding-top: 5rem;
  padding-bottom: 3rem;
  // display: flex;
  display: grid;
  grid-template-columns: repeat(3, minmax(430px, 430px));
  justify-content: center;
  // justify-content: center;
  gap: 7rem;
  row-gap: 8rem;
  flex-wrap: wrap;
  margin-bottom: 4rem;
}

// .recursos-container {
//   margin-top: 5rem;
// }

.card-container {
  width: 430px;
  // background-color: black;
  // height: 300px;
  background-color: white;
  border-radius: 0.75rem;
  // box-shadow: rgba(0, 0, 0, 0.1) 0rem 0.25rem 0.375rem -0.0625rem,
  //   rgba(0, 0, 0, 0.06) 0rem 0.125rem 0.25rem -0.0625rem;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.1);
  padding-bottom: 1rem;
}

.img-powerbi-container {
  // width: 100%;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  position: relative;
  margin-top: -2.5rem;
  border-radius: 1rem;
  object-fit: contain;
}

.img-powerbi {
  position: relative;
  object-fit: contain;
  width: 100%;
  color: rgb(52, 71, 103);
  z-index: 1;
  opacity: 1;
  box-shadow: rgba(0, 0, 0, 0.2) 0rem 0.25rem 0.375rem -0.0625rem,
    rgba(0, 0, 0, 0.2) 0rem 0.125rem 0.25rem -0.0625rem;
  border-radius: 1rem;
  background: transparent;
}
