.pricing-table {
  width: 100%;
}

.products-list-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.product-card {
  background: white;
  padding: 2rem;
  border-radius: 1rem;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.1);
  height: 100%;
  transition: transform 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.product-card:hover {
  transform: scale(1.05);
}

.no-hover {
  &:hover {
    transform: none;
  }
}

// @keyframes slideUp

.product-list-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3rem;
}

.products-cards-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(312px, 312px));
  grid-gap: 12px;
  justify-content: center;
  width: 100%;
  grid-auto-rows: 1fr;
}

.toggle-recurring-pay {
  & button {
    font-size: 1.2rem;
    padding: 1rem 3rem;
  }

  .Mui-selected {
    background-color: $c-blue !important;
    color: white !important;
  }
}

// .chilling-button {
//   height: 3rem;
//   margin-top: 3px;
//   display: flex;
//   justify-content: center;
//   color: white;
//   background-color: skyblue;
//   align-items: center;
//   text-transform: uppercase;
//   border-radius: 10px;

//   transition: background-position 400ms;
//   background-image: linear-gradient(
//     to right,
//     skyblue,
//     blue
//   ); /* Agregamos un degradado para el background /
//   background-size: 200% auto; / Ajustamos el tamaño del degradado /
//   background-position: 100% 50%; / Inicialmente, el degradado está totalmente a la derecha */
// }

// .chilling-button:hover {
//   background-position: 50% 700%;
// }
