.icon-expand::before {
  color: #97ccea !important;
}

.icon-expand {
  cursor: pointer;
}

.title {
    font-family: 'Poppins', sans-serif !important;
}