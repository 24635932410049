.brand-container{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 4rem;
    font-size: 3rem;
    font-family: $main-font-family;



    & p {
        margin: 0 !important;
    }
}

.footer-logo-container {
    width: 120px;
    height: 60px;



    & > img{
        object-fit: contain;
        width: 100%;
        height: 100%;
    }
}

.footer-brand__bim{
    font-weight: 700;
}