@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

* {
  font-family: 'Poppins', sans-serif !important;
}

.bold {
  font-weight: bold !important;
}

.login-btn {
  background-color: #3a6079 !important;
  color: #fff;
  border: none;
  border-radius: 3px;
  font-size: 12px !important;
  font-weight: bold;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Bebas+Neue&family=Lato&family=Prompt:wght@600&family=Sarabun&family=Ubuntu:wght@300;500&display=swap');
.header-app {
  z-index: 1000;
  flex-grow: 0 !important;
  background-color: white !important;
  height: 90px !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10rem;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
}

.wrapper-page {
  max-height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  background-color: #cecece;
  overflow-y: hidden;
}

.p-0 {
  padding: 0 !important;
}

.m-0 {
  margin: 0 !important;
}

.h-100 {
  height: calc(100vh - 64px) !important;
}

.b-white {
  background-color: white !important;
}

.icon-hub:before {
  background-image: url(https://img.icons8.com/ios/14/3A6079/uncheck-all.png); /* or https://raw.githubusercontent.com/primer/octicons/main/icons/stack-16.svg */
  background-size: cover;
}

.icon-project:before {
  background-image: url(https://img.icons8.com/ios/14/3A6079/box--v1.png); /* or https://raw.githubusercontent.com/primer/octicons/main/icons/organization-16.svg */
  background-size: cover;
}

.icon-my-folder:before {
  background-image: url(https://img.icons8.com/ios/14/3A6079/opened-folder.png);
  background-size: cover;
}

.icon-item:before {
  background-image: url(https://img.icons8.com/ios/14/3A6079/file--v1.png);
  background-size: cover;
}

.icon-version:before {
  background-image: url(https://raw.githubusercontent.com/primer/octicons/main/icons/clock-16.svg);
  background-size: cover;
}

.sidebar-tree {
  overflow: auto;
  height: 100%;
  text-overflow: inherit !important;
  background-color: white;
}

.inspire-tree .title {
  text-overflow: unset !important;
  overflow: initial !important;
}

a {
  color: #000000 !important;
  text-decoration: none !important;
}

.inspire-tree {
  padding-left: 3rem !important;
  margin: 1rem 0 !important;
  width: 100%;
}

/* SCROLL BAR */
.scrollbar-container {
  /* activar el overflow con scroll */
  overflow: auto;

  /* personalizar el color de fondo del scrollbar */
  scrollbar-color: #ccc #f4f4f4;

  /* personalizar el ancho del scrollbar */
  scrollbar-width: thin;
}

/* estilo del scrollbar en Chrome y Safari */
.scrollbar-container::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.scrollbar-container::-webkit-scrollbar-track {
  background: #f4f4f4;
}

.scrollbar-container::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 6px;
}

/* estilo del scrollbar en Firefox */
.scrollbar-container {
  scrollbar-color: #ccc #f4f4f4;
}

.scrollbar-container scrollbar-thumb {
  background-color: #ccc;
  border-radius: 6px;
}

.number-items-selected {
  border: 1px solid #42a3ed;
  background-color: #42a3ed;
  color: white;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15px;
  height: 15px;
  border-radius: 15px;
}

.search-properties {
  width: 100%;
  padding: 0.3rem 1rem;
  border: none !important;
}

.search-properties:focus {
  outline: none;
}

.modal-open {
  overflow: hidden !important;
}

.modal {
  overflow: hidden !important;
}

.container-pagination {
  margin: 1rem 0;
}

.table-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.swiper-pagination-bullet {
  background-color: #ffffff !important;
}

.btn-strap {
  color: white;
  font-size: 12px !important;
  color: white !important;
}

.btn-strap:hover {
  background-color: #3a6079 !important;
}
