.box-shadow {
  -webkit-box-shadow: 0px 0px 38px -8px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 0px 38px -8px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 0px 38px -8px rgba(0, 0, 0, 0.3);
}

.gradient-page {
  background: rgb(40, 145, 194);
  background: linear-gradient(
    180deg,
    rgb(194 236 255 / 40%) 0%,
    rgba(40, 145, 194, 0) 50%,
    rgba(0, 212, 255, 0) 100%
  );
}
